import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  Flex,
  Spacer,
  Image,
  // hooks
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';
import useGuestInfo from '@hooks/useGuestInfo';

import txtWording from './locales';
import { API_HOSTNAME, API_TABLE_NAME } from '@/constants';
import { BUTTON_PROPS } from '@/constants/colors';
import { IMG_WISHES } from '@/constants/assets';
import { LOADING_STATE, INPUT_COMMON_PROPS, FORM_LABEL_PROPS, ERROR_TYPE } from './types';

const enc = (string) => encodeURIComponent(string);

function WishesSection({ lang }) {
  const [loadingType, setLoadingType] = useState(LOADING_STATE.IDLE);
  const [wishlist, setWishlist] = useState([]);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { name: nameFromUrl } = useGuestInfo();

  const [name, setName] = useState(nameFromUrl);
  const [ucapan, setUcapan] = useState('');
  const calledOne = useRef(false);
  const toast = useToast();

  const handleSetState = (e, setState) => {
    const value = e.target.value;
    setErrorType(ERROR_TYPE);
    setState(value);
  };

  const finishLoading = () => {
    setLoadingType(LOADING_STATE.IDLE);
  };

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to get wishlist data
   * @return {void}
   */
  const getData = async () => {
    setLoadingType(LOADING_STATE.GET_DATA);

    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'GET',
      };

      const rawResult = await fetch(
        `${API_HOSTNAME}?action=read&tableName=${API_TABLE_NAME}`,
        options,
      );
      const response = await rawResult.json();

      if (response.success) {
        setWishlist(response.data || []);
      } else {
        console.error('ERR_WHEN_GET_DATA', 200);
      }
      finishLoading();
    } catch (e) {
      finishLoading();
      console.error('ERR_WHEN_CALL_DATA', 500);
    }

    calledOne.current = true;
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!name || !ucapan) {
      setErrorType({
        name: !name && txtWording.requiredField[lang],
        ucapan: !ucapan && txtWording.requiredField[lang],
      });
      return;
    }

    setLoadingType(LOADING_STATE.SUBMIT_DATA);

    try {
      const time = dayjs(new Date()).format('DD MMM YYYY | hh:mm:ss A');
      const config = `tableName=${API_TABLE_NAME}&action=insert_wish`;
      const query = `?${config}&nama=${enc(name)}&wish=${enc(ucapan)}&time=${enc(time)}`;

      const rawResult = await fetch(API_HOSTNAME + query, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      if (response.success) {
        setName('');
        setUcapan('');
        getData();
        handleSetAlert(true);
      } else {
        handleSetAlert(false);
      }
    } catch (e) {
      handleSetAlert(false);
    }

    finishLoading();
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    if (!calledOne.current) {
      getData();
    }
  }, []);

  return (
    <Box>
      <Box padding="32px 24px 62px 24px" bgColor="bgPrimary">
        {/* Title & Description sections */}
        <Box bgColor="bgSecondary" padding="24px 16px" borderTopRadius="16px">
          <Box display="flex" alignItems="center" textAlign="center" color="mainColorText">
            <Image
              src={IMG_WISHES}
              objectFit="cover"
              width="80px"
              height="80px"
              objectPosition="center"
              borderRadius="50%"
              marginRight="20px"
            />
            <Box width="calc(100% - 100px)">
              <Heading fontWeight="normal" fontSize={lang === "id" ?"3xl" :"2xl"}>
                {txtWording.title[lang]}
              </Heading>
            </Box>
          </Box>
        </Box>
        <Box width="100%" height="2px" bgColor="bgAlternative" />
        <Box padding="24px 24px 42px 24px" borderBottomRadius="16px" bgColor="bgSecondary">
          {/* Box for FORM */}
          <WithAnimation>
            <Box paddingTop="2">
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  onChange={(e) => handleSetState(e, setName)}
                  value={name}
                />
                <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
              </FormControl>
              <FormControl margin="16px 0" isInvalid={errorType.ucapan}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
                <Textarea
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  onChange={(e) => handleSetState(e, setUcapan)}
                  value={ucapan}
                />
                <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
              </FormControl>
              <Flex justifyItems="end">
                <Spacer />
                <Button
                  isLoading={loadingType === LOADING_STATE.SUBMIT_DATA}
                  size="sm"
                  padding="8px 24px"
                  fontWeight="light"
                  bgColor="bgAlternative"
                  fontFamily="body"
                  borderRadius="4px"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  color="white"
                  onClick={handleSubmit}
                  {...BUTTON_PROPS}
                >
                  {txtWording.send[lang]}
                </Button>
              </Flex>
            </Box>
          </WithAnimation>
          {/* Wishlist Card */}
          <WithAnimation>
            <Wishlist wishlistData={wishlist} loading={loadingType === LOADING_STATE.GET_DATA} />
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);