import React from 'react';
import { string } from 'prop-types';
import { Image, Box, Center, Button, useDisclosure, Heading, Text } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import ImageHover from '@components/Common/ImageHover';
import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BUTTON_PROPS } from '@/constants/colors';
import { IMG_GALLERY_1, IMG_GALLERY_2, IMG_LOGO } from '@/constants/assets';
import { ENABLE_AYAT_ALTERNATIVE_POSITION, ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';

import txt from './locales';
import Content from './Content';

function PhotoSection() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();

  return (
    <Box padding="42px 32px" bgSize="cover" bgColor="bgSecondary">
      {ENABLE_AYAT_ALTERNATIVE_POSITION && (
        <Box padding="2px 32px 32px 32px" textAlign="center" color="white">
          <WithAnimation>
            <Text fontSize="md" color="mainColorText">
              “{ENABLE_MUSLIM_LANGUAGE ? txt.ayatMuslim[lang] : txt.ayat[lang]}”
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text fontWeight="bold" marginTop="12px" fontSize="md" color="mainColorText">
              {ENABLE_MUSLIM_LANGUAGE ? txt.ayatNameMuslim[lang] : txt.ayatName[lang]}
            </Text>
          </WithAnimation>
        </Box>
      )}
      {false && (
        <Box>
          <WithAnimation>
            <Center>
              <Image src={IMG_LOGO} width="100%" maxW="100px" />
            </Center>
          </WithAnimation>
        </Box>
      )}
      <ImageHover
        images={[IMG_GALLERY_1, IMG_GALLERY_2]}
        imageProps={{ height: '250px', margin: '8px 0' }}
        firstImageProps={{ height: '450px', objectPosition: 'top' }}
      />
      <WithAnimation>
        <Heading
          margin="16px 0"
          marginTop="-32px"
          fontWeight="normal"
          color="secondaryColorText"
          textAlign="center"
          size="3xl"
          letterSpacing="2px"
          lineHeight="80%"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
        />
      </WithAnimation>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            size="sm"
            margin="16px 0 32px 0"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="bgAlternative"
            color="white"
            fontFamily="body"
            textTransform="uppercase"
            letterSpacing="1px"
            onClick={() => onOpen()}
            {...BUTTON_PROPS}
          >
            {txt.button[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content lang={lang} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);