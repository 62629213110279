import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Text,
  Divider,
  DrawerFooter,
  // hooks
  useMediaQuery,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import { THE_BRIDE } from '@/constants';
import { IMG_LOGO_INVERT } from '@/constants/assets';
import { BUTTON_PROPS, NAVIGATION_COLOR, ICON_NAVIGATION_COLOR } from '@/constants/colors';

import useMenudata from './useMenuData';
import txt from './locales';

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDesktopView] = useMediaQuery('(min-width: 1024px)');

  const menuData = useMenudata();
  const toast = useToast();
  const lang = useLang();

  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        ref={btnRef}
        borderRadius="50%"
        position="fixed"
        zIndex="12"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="xl"
        onClick={isOpen ? onClose : onOpen}
        bgColor={NAVIGATION_COLOR}
        icon={
          isOpen ? (
            <MdClose color={ICON_NAVIGATION_COLOR} />
          ) : (
            <MdMenu color={ICON_NAVIGATION_COLOR} />
          )
        }
        {...BUTTON_PROPS}
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={isDesktopView ? '30%' : '85%'}
          bgColor="bgSecondary"
          padding="24px 16px"
        >
          <DrawerBody textAlign="right">
            <Image src={IMG_LOGO_INVERT} width="95px" position="absolute" top="20px" right="36px" />
            <Box marginTop="140px">
              {filteredMenuData.map((i) => {
                return (
                  <div key={i.value}>
                    <Text
                      margin="24px 0"
                      color="mainColorText"
                      letterSpacing="3px"
                      fontSize="lg"
                      textTransform="uppercase"
                      cursor="pointer"
                      _hover={{ fontFamily: 'heading', textTransform: 'none', fontSize: '2xl' }}
                      onClick={() => onClickMenu(i.value, i.offset)}
                    >
                      {i.name}
                    </Text>
                    <Divider
                      bgColor="bgAlternative"
                      borderBottom="none"
                      height="2px"
                      width="100%"
                    />
                  </div>
                );
              })}
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="right" fontSize="xs" fontFamily="bodyAlternative">
              <Text color="mainColorText">Created with Love by Invitato</Text>
              <Text color="mainColorText">{`${new Date().getFullYear()} ${THE_BRIDE}`}</Text>
              <Text color="mainColorText">All Rights Reserved</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;
