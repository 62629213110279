import get from '@invitato/helpers/dist/getLocale';

export default {
  thanks: get('Terima Kasih', 'Thank You!'),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Kami mengharap pengertian Anda atas semua pemberlakuan protokol kesehatan di hari pernikahan. Sampai jumpa di hari bahagia kami!', 
    'Your presence on our wedding day would bring us immense joy. We are grateful for your kind words, prayers, and thoughtful gestures. We eagerly anticipate sharing this special day with you! Thank you for being a part of our celebration.'
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Anda akan tetap ada di hati dan pikiran kami, meski kami terpaksa tidak bisa mengundang karena keadaan. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Kami mengharap pengertian Anda atas berlakunya peraturan pembatasan jumlah tamu undangan.', 
    `It will be a joy for us if you are still willing to give your blessing from afar. Thank you for all the words, prayers, and attention given.`
  ),
};