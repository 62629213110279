export default {
  dear: {
    id: 'Kepada Bapak/Ibu/Saudara/i,',
    en: 'Dear Mr/Mrs/Ms,',
  },
  guest: {
    id: 'Keluarga & Teman-teman',
    en: 'Family & Friends',
  },
  welcomingInvitationMuslim: {
    id: `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    en: `Together with the blessing of Allah Subhanahu wa Ta'ala, we cordially request the honor of your presence at the wedding celebration of:`,
  },
  welcomingInvitationGeneral: {
    id: `Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    en: 'With hearts full of joy and the blessings of God, we warmly invite you to join us in celebrating the union of:',
  },
  welcomingAnnouncementMuslim: {
    id: `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    en: `Together with the blessing of Allah Subhanahu wa Ta'ala, we joyfully announce the upcoming marriage of:`,
  },
  welcomingAnnouncementGeneral: {
    id: 'Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:',
    en: 'Together with joyful hearts and the grace of God, we joyfully announce the upcoming marriage of:',
  },
};