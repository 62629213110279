import React from 'react';

import { string } from 'prop-types';
import { Box, Center } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import CountDown from '@/components/Common/CountDown/V3';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import { BG_COUNTING_DOWN } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';

import txt from './locales';

function CountingDown() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      padding="52px 24px 62px 24px"
      minHeight="400px"
      bgImage={`url(${BG_COUNTING_DOWN})`}
      bgSize="cover"
      bgPosition="top"
    >
      <WithAnimation left>
        <Box width="100%" borderRadius="16px" marginTop="-24px">
          <CountDown lang={lang} />
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendar
                  text={txt.reminder[lang]}
                  size="sm"
                  textTransform="uppercase"
                  fontFamily="body"
                  fontWeight="light"
                  color="white"
                  bgColor="bgAlternative"
                  boxShadow="xl"
                  letterSpacing="2px"
                  {...BUTTON_PROPS}
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </WithAnimation>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;