import React from 'react';
import { Box, Divider } from '@chakra-ui/react';

import ImageHover from '@components/Common/ImageHover';
import LoveStory from '@components/Section/LoveStory/Lazy';

import { IMG_PHOTO_1, IMG_PHOTO_2, IMG_PHOTO_3 } from '@/constants/assets';
import * as FEATURE from '@/constants/feature-flags';

function WelcomingGallery() {
  return (
    <Box bgColor="bgPrimary" padding="16px 0">
      <Divider
        width="50%"
        opacity={1}
        height="2px"
        borderColor="bgAlternative"
        borderBottomWidth="2px"
      />
      <Box padding="32px 52px">
        <ImageHover
          images={[IMG_PHOTO_1, IMG_PHOTO_2, IMG_PHOTO_3]}
          imageProps={{ height: '200px' }}
        />
      </Box>
      {FEATURE.ENABLE_LOVE_STORY && <LoveStory />}
      <Box width="100%" maxW="500px" position="absolute">
        <Divider
          width="50%"
          opacity={1}
          height="2px"
          borderColor="bgAlternative"
          borderBottomWidth="2px"
          position="absolute"
          right="0"
        />
      </Box>
    </Box>
  );
}

export default WelcomingGallery;
